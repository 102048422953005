<template>
  <div class="GlobalFormDemo">
    <GlobalForm :init-data="initData" :form-item-list="formItemList" :inline="true" :labelWidth="90" round :needBtnLoading="true"
      @handleConfirm="handleConfirm" confirmBtnName="查询">
      <el-form-item class="el_from" label="收货方名称" slot="operationSelect" style="margin:0" label-width="93px">
        <el-select v-model="merchantId" placeholder="请选择商家" filterable clearable>
          <el-option v-for="item in shanpsSelectList" :key="item.merchantId" :label="item.merchantName"
            :value="item.merchantId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" v-if="state!=100" label="下单通道" slot="singleChannel" style="margin:0">
        <el-select v-model="channel" placeholder="请选择" clearable>
          <el-option v-for="item in SingleOptions" :key="item.key"
            :label="item.name" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="下单方式" slot="delivery" style="margin:0">
        <el-select v-model="sendType" placeholder="请选择" clearable>
          <el-option v-for="item in sendTypeOptions" :key="item.key"
            :label="item.name" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="门店商家" slot="companyId" style="margin:0">
        <el-select v-model="companyId" placeholder="请选择门店商家" filterable clearable @change="handleChangeInquiry">
          <el-option v-for="item in merchantSelectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>


      <el-form-item class="el_from" label="所属门店" slot="storeId" style="margin:0">
        <el-select v-model="storeId" placeholder="请先选择所属门店" :disabled="isHasCompany" filterable clearable>
          <el-option v-for="item in storeList" :key="item.key" :label="item.value" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <!--

        {
          key: "expressNo",
          type: "input",
          labelName: "物流单号",
          placeholder: "请输入物流单号查询",
        },

 -->

      <el-form-item label-width="80px" class="el_from" label="物流单号" slot="expressNo" style="margin:0">
        <el-input v-model="expressNo" placeholder="请输入物流单号查询" clearable>
        </el-input>
      </el-form-item>
      <div slot="afterNotSignedDay">
        <div>
          <el-form-item v-if="state == 6" label="签收时间" slot="signTime" style="margin-right:10px;">
            <el-date-picker v-model='signTime' type="daterange" start-placeholder="开始日期" range-separator="~" 
              :picker-options="pickerOptions" end-placeholder="结束日期" clearable :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </el-form-item>
          <el-form-item v-if="state == 7" label="取消时间" slot="cancelTime" style="margin-right:10px;">
            <el-date-picker v-model='cancelTime' type="daterange" start-placeholder="开始日期" range-separator="~"
             :picker-options="pickerOptions" end-placeholder="结束日期" clearable :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </el-form-item>
          <el-form-item v-if="state == 7" label="取消渠道" slot="cancelOrigin" style="margin-right:10px;">
            <el-select v-model="cancelOrigin" clearable>
              <el-option v-for="item in cancelOriginOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="[1,2,6,7].includes(state)" label="实际快递状态" label-width="100px" slot="stateReal" style="margin-right:10px;">
            <el-select v-model="stateReal" clearable>
              <el-option v-for="item in stateRealOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label-width="0">
            <span>下单后</span>
            <el-select v-model="afterNotSignedDay" clearable placeholder="选择天数" style="width:100px">
              <el-option v-for="item in signedDayList" :key="item" :label="item + '天'" :value="item" />
            </el-select>
            <span>未签收</span>
          </el-form-item>
        </div>
      </div>
      <div slot="AddSlot">
        <el-button size="small" :loading="isExport" type="success" icon="el-icon-download"
          @click="clickExcel">导出Excel</el-button>
      </div>
    </GlobalForm>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String,
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      state:1,
      isExport: false,
      expressNo: "",
      afterNotSignedDay: "",
      stateReal: '',
      stateRealOptions: [
        { label: '已下单', value: '1'},
        { label: '进行中', value: '2'},
        // { label: '已完成', value: '6'},
        { label: '已签收', value: '6'},
        { label: '已取消', value: '7'},
        // { label: '已删除', value: '99'},
      ],
      SingleOptions:[
        { key: 'dl', name: '鼎霖' },
        { key: 'sf', name: '顺丰快递管家' },
      ],
      sendTypeOptions:[
        { key: '01', name: '门店下单' },
        { key: '02', name: '平台下单' },
        { key: '03', name: '第三方下单' },
      ],
      cancelOrigin: '',
      cancelOriginOptions: [
        { label: '门店', value: '00'},
        { label: '平台', value: '01'},
        { label: '第三方', value: '02'},
      ],
      arrssy: {},
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (
              time.getTime() > this.pickerMinDate + this.day31 ||
              time.getTime() < this.pickerMinDate - this.day31
            );
          }
          return false;
        },
      },
      merchantId: "",
      signTime:null,
      cancelTime:null,
      initData: {
        entryTime: [
          moment()
            .day(moment().day() - 6)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
        // signTime: [
        //   moment()
        //     .day(moment().day() - 6)
        //     .format("YYYY-MM-DD 00:00:00"),
        //   moment().format("YYYY-MM-DD 23:59:59"),
        // ],
        // cancelTime: [
        //   moment()
        //     .day(moment().day() - 6)
        //     .format("YYYY-MM-DD 00:00:00"),
        //   moment().format("YYYY-MM-DD 23:59:59"),
        // ],
      },
      formItemList: [
        { slotName: "operationSelect" },
        { slotName: "singleChannel" },
        {
          slotName: "delivery"
        },
        {
          slotName: "companyId"
        },
        {
          slotName: "storeId"
        },
        // {
        //   key: "staffName",
        //   type: "input",
        //   labelName: "发货人",
        //   lableWidth: "66px",
        //   placeholder: "请输入门店员工姓名",
        // },
        { slotName: "expressNo" },
        // {
        //   key: "state",
        //   type: "select",
        //   labelName: "物流状态",
        //   option: [
        //     {
        //       value: "2",
        //       label: "运输中",
        //     },
        //     {
        //       value: "6",
        //       label: "已完成",
        //     },
        //     {
        //       value: "7",
        //       label: "已取消",
        //     },
        //   ],
        // },

        {
          key: "expressCode",
          type: "select",
          labelName: "发货快递",
          option: [
            {
              value: "jd",
              label: "京东",
            },
            {
              value: "shunfeng",
              label: "顺丰",
            },
          ],
        },
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "时间",
          lableWidth: "54px",
          placeholder: "请选择时间",
          valueFormat: "timestamp",
          clearable: true,
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
        { slotName: 'signTime' },
        { slotName: 'cancelTime' },
        { slotName: 'cancelOrigin' },
        { slotName: 'stateReal' },
        { slotName: "afterNotSignedDay" },
      ],
      signedDayList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      merchantSelectList: [],
      storeList: [],
      isHasCompany: true,
      storeId: "",
      shanpsSelectList: [],
      companyId: "",
      sendType: "",
      channel:''
    };
  },
  created() {
    this.getmercate()
    _api.getSelectList().then((res) => {
      if (res.code === 1) {
        this.merchantSelectList = res.data;
      }
    });

    let expressNo = this.$route.query.id;
    console.log(expressNo);
    if (expressNo) {
      this.expressNo = expressNo;
    }
    // this.signTime =[startTime, endTime];
    // this.cancelTime = [startTime, endTime];
    this.handleConfirm(this.initData);
  },
  computed: {
    baseRequest() {
      return this.$store.state.tagsView.excelParams;
    },
  },
  watch: {
    companyId(newVal) {
      console.log(newVal);
      if (newVal != "") {
        this.isHasCompany = false;
      } else {
        this.storeId = "";
        this.isHasCompany = true;
      }
    },
    tabIndex(val){
      if(val == 0){
        this.state = 1
      }else if (val == 1){
        this.state = 2
      }else if (val == 2){
        this.state = 6
      }else if (val == 3){
        this.state = 7
      }else if(val == '4'){
        this.state = 100
        this.channel=""
      }
    },
  },
  methods: {
    //获取回收商家
    getmercate() {
      this.shanpsSelectList = []
      _api.listDptmMerchantList({ pageNum: 1, pageSize: 999 }).then((res) => {
        if (res.code === 1) {
          this.shanpsSelectList = res.data;
        }
      });
    },
    clickExcel() {
      this.arrssy = this.baseRequest
      this.isExport = true
      _api.excelexpressorder(this.arrssy).then((res) => {
        const content = res;
        const blob = new Blob([content]);
        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              this.$message.error(data.msg || "服务异常");
            }
          } catch (err) {
            const fileName = "运单监控.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");

        this.isExport = false
      }).catch(err => {
        this.isExport = false
      });
    },
    handleChangeInquiry(val) {
      console.log(val);
      this.companyId = val;
      if (val != "") {
        this.storeId = "";
        this.getStoreSelectList();
      }
    },
    // 获取门店列表
    getStoreSelectList() {
      _api.getStoreSelectList({ companyId: this.companyId }).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.storeList = res.data;
        }
      });
    },
    handleConfirm(data,cd) {
      console.log(123);
      let SeachParams = {};
      if (data) {
        let startTime = "";
        let endTime = "";
        if (data.entryTime === null) {
          (startTime = ""), (endTime = "");
        } else {
          (startTime = moment(data.entryTime[0]).format("x")),
            (endTime = moment(data.entryTime[1]).format("x"));
        }
        let signStartTime = "";
        let signEndTime = "";
        if (this.signTime === null) {
          (signStartTime = ""), (signEndTime = "");
        } else {
          (signStartTime = moment(this.signTime[0]).format("x")),
            (signEndTime = moment(this.signTime[1]).format("x"));
        }

        let cancelStartTime = "";
        let cancelEndTime = "";
        if (this.cancelTime === null) {
          (cancelStartTime = ""), (cancelEndTime = "");
        } else {
          (cancelStartTime = moment(this.cancelTime[0]).format("x")),
            (cancelEndTime = moment(this.cancelTime[1]).format("x"));
        }

        SeachParams = {
          merchantId: this.merchantId,
          sendType: this.sendType,
          channel:this.channel,
          companyId: this.companyId,
          expressNo: this.expressNo,
          afterNotSignedDay: this.afterNotSignedDay,
          staffName: data.staffName,
          state: data.state,
          storeId: this.storeId,
          startTime: startTime,
          endTime: endTime,
          signStartTime: signStartTime,
          signEndTime: signEndTime,
          cancelStartTime: cancelStartTime ,
          cancelEndTime: cancelEndTime,
          cancelOrigin: this.cancelOrigin,
          stateReal: this.stateReal,
          pageNum: 1,
          pageSize: 10,
          expressCode: data.expressCode,
          disuseLoding: () => {
            if (typeof cd === 'function') {
              cd()
            }
          },
        };
      }
      // this.arrssy = SeachParams;
      console.log(SeachParams, this.initData.expressNo);
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {}
</style>
