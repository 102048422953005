<template>
  <div class="class-list">
    <GlobalInfoBar
      title="运单监控"
      content="物流订单的管理"
    />

    <div class="statusTabs">
      <div
        class="itemTab"
        v-for="(item, index) in tabData"
        :key="item.paramResult"
        :class="tabIndex == index ? 'tabActive' : ''"
        @click="tabAction(index)"
      >
        <div class="itemName">
          <span class="name">{{ item.text }}</span>
          <i class="el-icon-warning-outline" @mouseenter="item.isHover = true" @mouseleave="item.isHover = false">
            <div v-show="item.isHover" class="popNotion">
              <div v-html="item.notion"></div>
            </div>
          </i>
        </div>
        <div class="itemCount">
          <span  class="num">{{ item.num > 9999 ? "9999+" : item.num }}</span>
          <!-- <span class="unit">{{ item.unitName }}</span> -->
        </div>
        <div class="itemUn">
          <span>{{ item.name }}</span>&nbsp;
          <span class="num">{{ item.unNum > 9999 ? "9999+" : item.unNum }}</span>
          <!-- <span v-else class="num">***</span> -->
          <span class="unit">{{ item.unitName }}</span>
        </div>
      </div>
    </div>

    <el-checkbox v-model="timeoutType" style="margin:0px 0 13px 10px;">{{ defaultContent[tabIndex] }}</el-checkbox>
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <From :tabIndex="tabIndex"/>
      </div>
      <Table :tabIndex="tabIndex" @getSendData="getSendData" :timeoutType="timeoutType" @changeTimeoutType="changeTimeoutType"/>
    </GlobalChunk>
  </div>
</template>

<script>
import From from "./Form";
import Table from "./Table";
import _api from "@/utils/request";

export default {
  name: "class-list",
  components: {
    From,
    Table,
    // TabStatusSelect
  },
  data() {
    return {
      tabIndex:0,
      tabData: [
        {
          paramResult: "00",
          text: "待取件", //waitPick
          num: 0,
          name: "超时未取件", //timeoutWaitPick
          unNum: 0,
          isHover:false,
          notion:  `<div style="height:20px;line-height:20px;">1.待取件</div>
                    <div>旧机已下快递，等待上门取件</div>
                    <div style="height:20px;line-height:20px;">2.超时未取件</div>
                    <div style="color:red;">快递下单超1天未取件，标记为超时未取件</div>`,
          unitName:'单'
        },
        {
          paramResult: "01",
          text: "运输中",//inTransit
          num: 0,
          name: "超时未签收",//onlineSignError
          unNum: 0,
          isHover:false,
          notion:  `<div style="height:20px;line-height:20px;">1.运输中</div>
                    <div>旧机已发货，快递在运输中</div>
                    <div style="height:20px;line-height:20px;">2.超时未签收</div>
                    <div style="color:red;">快递下单超3天未签收，标记为超时未签收</div>`,
          unitName:'单'
        },
        {
          paramResult: "02",
          text: "已签收", //signFor
          num: 0,
          name: "未收货",
          unNum: 0, //onlineSignNoTake
          isHover:false,
          notion:  `<div style="height:20px;line-height:20px;">1.已签收</div>
                    <div>快递已签收</div>
                    <div style="height:20px;line-height:20px;">2.未收货</div>
                    <div style="color:red;">已签收快递中，还未收货的旧机数量</div>`,
          unitName:'台'
        },
        {
          paramResult: "03",
          text: "已取消", //cancel
          num: 0,
          name: "",
          unNum: null,
          isHover:false,
          notion:  `<div style="height:20px;line-height:20px;">1.已取消</div>
                    <div>快递已取消</div>`,  
          unitName:null
        },
        {
          paramResult: "04",
          text: "线下发货", //offlineSendNum
          num: 0,
          name: "未收货", //offlineSignNoTake
          unNum: 0,
          isHover:false,
          notion:  `<div style="height:20px;line-height:20px;">1.线下发货</div>
                    <div>使用线下发货的快递</div>
                    <div style="height:20px;line-height:20px;">2.未收货</div>
                    <div style="color:red;">使用线下发货的旧机中，还未收货的旧机数量</div>`,          
          unitName:'台'
        },
      ],
      timeoutType:false,
      defaultContent: [
        "筛选超时未取件",
        "筛选超时未签收",
        "筛选含未收货订单的快递",
        "查询平台强制取消运单",
        "筛选含未收货订单的快递",
      ],
    };
  },
  created(){
  },
  watch:{},
  methods: {
    // 发货状态tab
    tabAction(i) {
      this.tabIndex = i;
    },
    getSendData(data){
      console.log('~~~',data);
      this.tabData[0].num = data.waitPick
      this.tabData[0].unNum = data.timeoutWaitPick
      this.tabData[1].num = data.inTransit
      this.tabData[1].unNum = data.onlineSignError
      this.tabData[2].num = data.signFor
      this.tabData[2].unNum = data.onlineSignNoTake
      this.tabData[3].num = data.cancel
      this.tabData[4].num = data.offlineSendNum
      this.tabData[4].unNum = data.offlineSignNoTake
    },
    changeTimeoutType(val){
      this.timeoutType = val
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.tab{
  margin: -10px 0 10px;
}
.statusTabs {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  color: #535455;
  padding: 0 10px 20px;
  .itemTab {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 130px;
    height: 140px;
    padding: 10px;
    background: #f1f7ff;
    border: 2px solid #f0f0f0;
    box-shadow: 0px 4px 5px 0px rgba(76, 132, 255, 0.4);
    border-radius: 6px;
    margin-right: 15px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    .itemName{
      display: flex;
      .el-icon-warning-outline{
        display: flex;
        align-items: center;
        margin-left: 2px;
        position: relative;
        .popNotion{
          position: absolute;
          left: 25px;
          top: -35px;
          z-index: 999;
          min-width: 250px;
          min-height: 50px;
          padding: 10px;
          border-radius: 5px;
          font-size: 12px;
          background-color: #fff;
          color: #333;
          border: 1px solid #EAEAEA;
          box-shadow: -6px 9px 30px 0px rgba(168,168,168,0.36);
          &::after{
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            left: -8px;
            top: 39px;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-right: 8px solid #fff;
          }
        }
      }

    }
    .itemCount {
      color: #4c84ff;
      .num {
        font-family: PingFang-SC-Heavy;
        font-size: 25px;
        height: 25px;
        line-height: 25px;
        font-weight: 700;
      }
      .unit {
        font-size: 16px;
      }
    }
    .itemUn {
      font-size: 12px;
      font-family: FZLanTingHei-M-GBK;
      color: #ff7284;
    }
  }
  .tabActive {
    border: 2px solid #73aaff;
  }
}
</style>