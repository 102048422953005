<template>
  <el-dialog
    title="查询提示"
    v-loading="loading"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="500px"
    @closed="close"
  >
    <div style="text-align: center">
      <p style="margin-bottom: 20px">
        查询成功！当前运单实际物流状态为
        <span style="color:red">{{ optionsFilter(info.stateReal, stateOptions) }}</span>
        <span v-if="row.expressCode == 'jd'" style="color:red">-{{info.jdActionDesc}}</span>
      </p>
      <p>
        当前繁星物流状态为{{ optionsFilter(info.state, stateOptions) }}<span v-if="row.expressCode == 'jd'">-{{info.jdActionDesc}}</span>，
        <span v-if="info.stateReal == info.state">状态一致无需更新</span>
        <span v-else>是否确认更新为{{optionsFilter(info.stateReal, stateOptions)}}<span v-if="row.expressCode == 'jd'">-{{info.jdActionDesc}}</span>？</span>
      </p>
    </div>
    <span slot="footer" class="dialog-footer">
      <template v-if="info.stateReal != info.state">
        <el-button @click.native="close">取消</el-button>
        <el-button type="primary" @click.native="confirm">确定</el-button>
      </template>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: 'LogisticsInquiryStatusDia',
  data() {
    return {
      visible: false,
      loading: false,
      row: {},
      info: {
        stateRealName: ''
      },
      stateOptions: [
        { label: '已下单', value: '1'},
        { label: '进行中', value: '2'},
        { label: '已完成', value: '6'},
        { label: '已取消', value: '7'},
      ],
    }
  },
  methods: {
    open(row) {
      this.row = row
      this.getData()
    },
    getData() {
      this.loading = true
      _api.findRealOrderDetail({
        expressNo: this.row.expressNo
        // expressNo: 'JDVG02478530169'
        // expressNo: 'SF1634650911545'
      }).then(res => {
        this.info = res.data
        this.visible = true
      }).finally(() => {
        this.loading = false
      })
    },
    optionsFilter(v, options) {
      return options.find(item => item.value == v)?.label
    },
    confirm() {
      _api.updateRealOrderState(this.info).then(() => {
        this.$message.success("操作成功");
        // 关闭弹窗
        this.visible = false;
        this.$emit('confirm')
      });
    },
    close() {
      this.visible = false;
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.offline_box {
  width: 100%;

  > h2 {
    color: #ff687b;
    font-size: 12px;
    font-weight: 900;
  }

  > p {
    font-size: 14px;
    margin: 20px 0;
    color: #333;
    text-align: center;
  }
  .cancel_offline_note{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f2f2f2;
    height: 55px;
  }
}
</style>